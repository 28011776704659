*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: #002673;
  font-size: 18px;
  color: rgb(255, 243, 243);
  padding: 0.5rem 1.5rem 85px 1.5rem;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

header img.logo {
  height: 2rem;
  pointer-events: none;
}

a, a:link, a:visited, a:active {
  color: #61dafb;
  text-decoration: none;
}

a:hover {
  color: #ff5c16;
}

header h1 {
  font-size: 2rem;
  padding-bottom: 0.5rem;
}

main {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 350px;
  margin: 0 auto;
}
main h2 {
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 0.5rem 0;
}
div.field label, div.field span {
  padding-top: 0.25rem;
}
summary {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
h3, h4 {
  text-align: center;
  font-size: 2rem;
  margin: 0;
}
h4 {
  font-weight: normal;
  font-size: 1.8rem;
}
