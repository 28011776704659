main.help h2 {
  font-size: 2rem;
}

main.help p {
  text-align: left;
  margin: 0.5rem 0 1rem 0;
  line-height: 1.6rem;
}
main.help p img.icon {
  border-radius: 1rem;
  float: left;
  margin: 0.5rem 1rem 0.5rem 0;
}

main.help p.version {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

div.terms-conditions {
  margin-top: 2rem;
}
div.terms-conditions,
div.privacy-policy {
  text-transform: uppercase;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
div.terms-conditions h4,
div.privacy-policy h4 {
  font-weight: bold;
  text-decoration: underline;
  font-size: 1rem;
}
div.terms-conditions p,
div.privacy-policy p {
  margin-top: 0.75rem;
  text-align: justify;
  font-size: 0.75rem;
  line-height: 1rem;
}
