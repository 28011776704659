div.post-session h2 {
  margin-top: 2rem;
}

button {
  display: block;
  padding: 0.5rem 0.75rem;
  margin: 1.5rem auto 0 auto;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: #61dafb;
  border: 1px solid blue;
  cursor: pointer;
}
button.resetBtn {
  background-color: rgb(204, 48, 48);
  color: white;
  font-weight: bold;
}
button.disabled {
  visibility: hidden;
}

button.noTarget {
  background-color: rgb(41, 128, 7);
  color: white;
  font-weight: bold;
  margin-bottom: 3rem;
}

summary p {
  text-align: center;
  margin: 0;
}
summary h2 {
  font-size: 3rem;
}

summary.post {
  margin-top: 3rem;
}

div.confirmReset {
  display: flex;
  flex-direction: row;
  place-items: center;
  gap: 1rem;
}
div.confirmReset button {
  flex-grow: 1;
}

summary p.temperature {
  margin-top: 0.5rem;
}
span.data {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  font-weight: bold;
}
