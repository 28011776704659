nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  place-items: center;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 1px;
  background-color: black;
}
nav.fullscreen {
  padding-bottom: 20px;
}

nav div.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  place-items: center;
  flex-grow: 1;
  gap: 0.5rem;
  background-color: #002e89;
  padding: 0.75rem;
  cursor: pointer;
}
nav div.btn.active {
  background-color: #0045ce;
}

nav div.btn img {
  height: 30px;
  color: rgb(255, 243, 243);
}
nav div.btn span {
  width: fit-content;
  padding-top: 0.25rem;
}
