.field {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.field > span {
  width: 1.5rem;
  text-align: left;
}

label {
  display: block;
  flex-grow: 1;
  width: fit-content;
  height: fit-content;
}

label {
  display: inline;
  color: inherit;
  font-size: inherit;
}

input,
input:focus-visible {
  display: inline;
  height: 2rem;
  color: inherit;
  font-size: inherit;
}

input,
input:focus,
input:focus-visible {
  background: inherit;
  border-color: rgb(255, 243, 243);
  border-width: 0 0 2px 0;
  outline: none;
  text-align: right;
  width: 6rem;
  font-family: 'Courier New', Courier, monospace;
}
input.error {
  border-color: red;
}

span.tagline {
  display: block;
  font-size: small;
  font-style: italic;
}
